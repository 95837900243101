import React, { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import _debounce from 'lodash/debounce';
import { Action, Pagination } from 'mediasoil-shared-components/components/';
import { date, categories, products, inventory } from '../../../content/productData.json';
import styles from './styles';

const PAGE_SIZE = 60;
const formattedDate = format(new Date(date), 'M/dd/yyyy');

const Products = React.memo(({ queryString }) => {
  const [currentQuery, setCurrentQuery] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');
  const [currentSort, setCurrentSort] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [popstate, setPopState] = useState(0);
  const [visibleProducts, setVisibleProducts] = useState(products);
  const paginationIndex = (currentPage - 1) * PAGE_SIZE;
  const vpln = visibleProducts.length;
  let showing = paginationIndex + PAGE_SIZE;

  if (showing > vpln) showing = vpln;

  const isFirstRun = useRef(true);
  const formRef = useRef(null);
  const popStateFn = () => setPopState(Date.now());
  const debouncedSetCurrentQuery = _debounce(({ target }) => {
    setCurrentQuery(target.value.trim());
    setCurrentPage(1);
  }, 250);

  // Parse query string on page load and set popstate handler
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const { q, category, sort, page } = {
      q: params.get('q') || '',
      category: params.get('category') || '',
      sort: params.get('sort') || '',
      page: params.get('page') || 1,
    };

    if (currentQuery !== q) {
      setCurrentQuery(q);
    }

    if (currentCategory !== category) {
      setCurrentCategory(category);
    }

    if (currentSort !== sort) {
      setCurrentSort(sort);
    }

    if (currentPage !== page) {
      setCurrentPage(+page || 1);
    }

    window.addEventListener('popstate', popStateFn);
  }, [popstate, queryString]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handle any updates, update query string and perform sort
  useEffect(() => {
    // Update Form Fields
    const form = formRef?.current;

    if (form) {
      form.q.value = currentQuery;
      form.sort.querySelector(`option[value="${currentSort}"]`).selected = true;
      form.category.querySelector(`option[value="${currentCategory}"]`).selected = true;
    }

    // Update URL (not on the first run though)
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      window.history.pushState(
        null,
        '',
        `${window.location.origin}${window.location.pathname}?category=${currentCategory}&q=${currentQuery}&page=${currentPage}&sort=${currentSort}`
      );
    }

    // Do Filtering, Sorting
    setVisibleProducts(
      products
        .filter(
          ({ name, category }) =>
            (currentCategory === '' || category === currentCategory) &&
            (currentQuery === '' || name.toLowerCase().includes(currentQuery.toLowerCase()))
        )
        .sort((a, b) => {
          const [type, dir] = currentSort.split('|');
          let A, B;

          if (type) {
            A = a.name;
            B = b.name;

            if (type === 'price') {
              A = parseFloat(a.variations[0].price.slice(1));
              B = parseFloat(b.variations[0].price.slice(1));
            }

            if (A > B) {
              return 1 * dir;
            } else if (A < B) {
              return -1 * dir;
            }
          }

          return 0;
        })
    );

    return () => window.removeEventListener('popstate', popStateFn);
  }, [currentQuery, currentCategory, currentSort, currentPage]);

  return (
    <>
      <form className={styles.form} method="get" onSubmit={evt => evt.preventDefault()} ref={formRef} role="search">
        <label className={styles.searchLabel} htmlFor={styles.search}>
          Search:
        </label>
        <input
          className={styles.search}
          id={styles.search}
          name="q"
          placeholder="Product Name"
          type="search"
          defaultValue={currentQuery}
          onChange={evt => {
            evt.persist();
            debouncedSetCurrentQuery(evt);
          }}
        />
        <label className={styles.categoryLabel} htmlFor={styles.category}>
          Category:
        </label>
        <div className={styles.select}>
          <select
            className={styles.category}
            id={styles.category}
            defaultValue={currentCategory}
            name="category"
            onChange={({ target }) => {
              setCurrentCategory(target.value);
              setCurrentPage(1);
            }}
          >
            {[
              {
                id: '',
                name: 'All Categories',
              },
            ]
              .concat(categories)
              .map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
          </select>
        </div>
        <label className={styles.sortLabel} htmlFor={styles.sort}>
          Sort:
        </label>
        <div className={styles.select}>
          <select
            className={styles.sort}
            id={styles.sort}
            defaultValue={currentSort}
            name="sort"
            onChange={({ target }) => {
              setCurrentSort(target.value);
              setCurrentPage(1);
            }}
          >
            <option value="">Name: A to Z</option>
            <option value="name|-1">Name: Z to A</option>
            <option value="price|1">Price: Low to High</option>
            <option value="price|-1">Price: High to Low</option>
          </select>
        </div>
        <output className={styles.output} name="results">
          Showing {paginationIndex + 1}-{showing} of {vpln} products:
        </output>
        <small className={styles.small}>
          Prices and stock updated as of <time dateTime={date}>{formattedDate}</time>.
        </small>
      </form>

      <ol className={styles.ol}>
        {visibleProducts.slice(paginationIndex, paginationIndex + PAGE_SIZE).map(({ id, name, image, variations }) => {
          const currency = variations[0].price[0];
          const price = variations[0].price.slice(1);
          let Availability = (
            <span className={styles.availability}>
              <link itemProp="availability" href="http://schema.org/OutOfStock" />
              Out of Stock
            </span>
          );

          if (inventory.find(({ variation_id }) => variation_id === variations[0].id)?.quantity) {
            Availability = (
              <span className={styles.availability}>
                <link itemProp="availability" href="http://schema.org/InStock" />
                In stock
              </span>
            );
          }

          return (
            <li className={styles.li} key={id} itemScope itemType="http://schema.org/Product">
              <Action className={styles.a} href={`/products/${id}`}>
                <div className={styles.img}>
                  {image && (
                    <img
                      alt={name}
                      itemProp="image"
                      loading="lazy"
                      src={`https://images-production-f.squarecdn.com/unsafe/180x180/${image}`}
                      srcSet={`https://images-production-f.squarecdn.com/unsafe/360x360/${image} 2x`}
                      width="100%"
                    />
                  )}
                </div>
                <strong aria-hidden="true" className={styles.strong} itemProp="name">
                  {name}
                </strong>
                <div className={styles.details}>
                  <span content="USD" itemProp="priceCurrency">
                    {currency}
                  </span>
                  <span itemProp="price" content={price}>
                    {price}
                  </span>
                  {Availability}
                </div>
              </Action>
            </li>
          );
        })}
      </ol>
      <Pagination
        aria-label="Product Pagination"
        className={styles.pagination}
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        numberOfItems={vpln}
        setPage={(page, evt) => {
          evt.preventDefault();

          if (formRef?.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          }

          setCurrentPage(page);
        }}
      />
    </>
  );
});

export default Products;
