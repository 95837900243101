import { css } from 'linaria';

export default {
  form: css`
    display: flex;
    flex-direction: column;
    font-size: 1.25em;
    margin: 0 0 helpers.$gutter;

    @include helpers.mq(products) {
      display: grid;
      grid-template-columns: 1fr auto auto;
      grid-template-rows: auto auto auto;
    }
  `,
  searchLabel: css`
    font-weight: 500;
    grid-column: 1 / 2;
    grid-row: 1;
  `,
  search: css`
    border: 1px solid currentColor;
    border-radius: 0;
    grid-column: 1 / 2;
    grid-row: 2;
    margin: 0 0 helpers.$gutter;
    padding: helpers.$gutter / 2;
    width: 100%;

    @include helpers.mq(products) {
      margin: 0;
    }
  `,
  categoryLabel: css`
    font-weight: 500;
    grid-column: 2 / 3;
    grid-row: 1;
  `,
  category: css`
    border: 1px solid currentColor;
    grid-column: 2 / 3;
    grid-row: 2;
    margin: 0 0 helpers.$gutter;
    padding: helpers.$gutter / 2;

    @include helpers.mq(products) {
      border-width: 1px 0;
      margin: 0;
    }
  `,
  sortLabel: css`
    font-weight: 500;
    grid-column: 3 / 4;
    grid-row: 1;
  `,
  sort: css`
    border: solid currentColor;
    border-width: 1px;
    grid-column: 3 / 4;
    grid-row: 2;
    padding: helpers.$gutter / 2;
  `,
  output: css`
    display: block;
    font-size: 0.9em;
    font-weight: 500;
    grid-column: 1 / 3;
    grid-row: 3;
    letter-spacing: 0.015em;
    margin: helpers.$gutter * 2 0 0;
  `,
  small: css`
    align-self: self-end;
    display: block;
    font-size: 0.6em;
    grid-column: 3 / 4;
    grid-row: 3;
    text-align: right;
  `,
  ol: css`
    background: white;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: auto;
    padding: 1px;
    position: relative;
    width: 100%;

    &::after {
      box-shadow: 0 0 1px 1px #ccc inset;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
    }
  `,
  li: css`
    position: relative;
    width: 100%;

    @include helpers.mq(products-little) {
      width: 50%;
    }

    @include helpers.mq(products) {
      width: 25%;
    }
  `,
  a: css`
    background: #f2f2f2;
    box-shadow: 0 0 1px 1px #ccc;
    display: block;
    height: 100%;
    padding: helpers.$gutter;
    text-decoration: none;
    width: 100%;

    &:hover {
      background: #fff;
      color: currentColor;
    }
  `,
  img: css`
    height: 0;
    overflow: hidden;
    padding-bottom: 100%;
    width: 100%;

    img {
      max-width: 100%;
    }
  `,
  strong: css`
    border: solid currentColor;
    border-width: 3px 0;
    display: block;
    margin: 0.5em 0;
    overflow: hidden;
    padding: 0.5em 0;
    width: 100%;

    @include helpers.mq(products) {
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `,
  details: css`
    display: flex;
  `,
  availability: css`
    flex: 1;
    text-align: right;
  `,
  pagination: css`
    justify-content: center;
    margin: helpers.$gutter 0;

    a {
      border: 2px solid black;
      color: black;
      display: block;
      line-height: 1.65em;
      padding: helpers.$gutter/4 helpers.$gutter/2;
      text-align: center;
      text-decoration: none;

      &:not([href]) {
        border-color: transparent;
        pointer-events: none;
      }
    }

    ol {
      margin: 0 helpers.$gutter / 2;

      a {
        border-style: dashed;
        height: 2.25em;
        width: 2.25em;

        &[aria-current] {
          border-style: solid;
        }
      }
    }

    li {
      height: 100%;
    }
  `,
  select: css`
    $size: 1em;

    position: relative;

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 32 32'%3E%3Cpath d='M7 12l9 9 9-9H7z'/%3E%3C/svg%3E");
      content: '';
      display: inline-block;
      height: 1em;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: $size;
    }

    select {
      appearance: none;
      border-radius: 0;
      padding-right: $size;
      width: 100%;
    }
  `,
};
