import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Main from '../components/main';
import Products from '../components/products';

export default ({ location }) => {
  return (
    <Layout>
      <SEO title="Products" />
      <Main>
        <h1>Products</h1>
        <Products queryString={location.search} />
      </Main>
    </Layout>
  );
};
